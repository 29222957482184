import { FC, useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { MenuComponent } from '../../../_metronic/assets/ts/components';
import { useCategoryMaterialsContext } from '../../../context/context';

type Props = {
  params?: any;
  level: number;
  updateValue?: any;
};

const MaterialCategoryItemMultiple: FC<Props> = ({ level, params, updateValue }) => {
  const {
    getCategoryMaterialsForFilters,
    getCategoryMaterialDetalisForFilters,
    getMultipleCategoryMaterialDetalisForFilters,
  } = useCategoryMaterialsContext();

  const [firstLevelOptions, setFirstLevelOptions] = useState([]);
  const [secondFilterOptions, setSecondFilterOptions] = useState([]);
  const [familiesItem, setFamiliesItem] = useState<any>([]);
  const [subFamiliesItem, setSubFamiliesItem] = useState<any>([]);

  useEffect(() => {
    getCategoryMaterialsForFilters({ level: 1 }).then((res: any) => {
      setFirstLevelOptions(res.data.data);
    });
    switch (level) {
      case 2:
        if (params.parent_id) {
          getCategoryMaterialDetalisForFilters(params.parent_id).then((res: any) => {
            setFamiliesItem([
              {
                label: res.data?.name,
                value: res.data.id,
              },
            ]);
          });
        }
        break;
      case 3:
        if (params.parent_id) {
          getCategoryMaterialDetalisForFilters(params.parent_id).then((res: any) => {
            setSecondFilterOptions(res?.data?.parent?.childs);

            setFamiliesItem([
              {
                label: res.data?.parent?.name,
                value: res.data?.parent?.id,
              },
            ]);

            setSubFamiliesItem([
              {
                label: res.data?.name,
                value: res.data?.id,
              },
            ]);
          });
        }
        break;
      case 4:
        if (params.parent_id) {
          getCategoryMaterialDetalisForFilters(params.parent_id).then((res: any) => {
            setSecondFilterOptions(res.data?.parent?.parent?.childs ?? []);
            setFamiliesItem([
              {
                label: res.data?.parent?.parent?.name,
                value: res.data?.parent?.parent?.id,
              },
            ]);

            setSubFamiliesItem([
              {
                label: res.data?.parent?.name,
                value: res.data?.parent?.id,
              },
            ]);
          });
        }
        break;
    }

    MenuComponent.reinitialization();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subFamiliesItem.length) {
      updateValue(subFamiliesItem);
      return;
    }
    if (familiesItem.length) {
      updateValue(familiesItem);
      return;
    }
    updateValue([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familiesItem, subFamiliesItem]);

  const getFamilyOptions = () => {
    return firstLevelOptions?.map((item: any) => ({ label: item.name, value: item.id }));
  };

  const getSubFamilyOptions = () => {
    return secondFilterOptions?.map((item: any) => ({ label: item.name, value: item.id }));
  };

  const onChangeFamilySelect = (value: any) => {
    setFamiliesItem(value);
    setSecondFilterOptions([]);
    setSubFamiliesItem([]);

    getMultipleCategoryMaterialDetalisForFilters(value.map((q: any) => q.value).join(',')).then((res: any) => {
      let items: any = [];
      res.data.forEach((q: any) => {
        items = [...items, ...q.childs];
      });

      setSecondFilterOptions(items ?? []);
    });
  };

  const onChangeSubFamilySelect = (value: any) => {
    setSubFamiliesItem(value);

    getMultipleCategoryMaterialDetalisForFilters(value.map((q: any) => q.value).join(',')).then((res: any) => {
      let items: any = [];
      res.data.forEach((q: any) => {
        items = [...items, ...q.childs];
      });
    });
  };

  return (
    <div className='p-4 mb-5 rounded d-flex align-items-center w-100 card-border'>
      <div className='form d-flex flex-wrap w-100'>
        <div className='col-sm-4 px-2'>
          <label className='fw-bold fs-6 mb-1'>Familii</label>
          <MultiSelect
            onChange={onChangeFamilySelect}
            options={getFamilyOptions()}
            labelledBy='Familii'
            // name='familii'
            value={familiesItem}
            dropdown-heading
            className='form-control-async-select-multiple'
          />
        </div>
        {(level === 3 || level === 4) && (
          <div className='col-sm-4 px-2'>
            <label className='fw-bold fs-6 mb-1'>Sub-Familii</label>
            <MultiSelect
              onChange={onChangeSubFamilySelect}
              options={getSubFamilyOptions()}
              labelledBy='Sub Familii'
              // name='sub-familii'
              value={subFamiliesItem}
              className='form-control-async-select-multiple'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { MaterialCategoryItemMultiple };
