import { useContext } from 'react';
import { AuthContext } from './providers/AuthProvider';
import { UsersContext } from './providers/UsersProvider';
import { ReportsContext } from './providers/ReportsProvider';
import { CurrencyContext } from './providers/CurrencyProvider';
import { PartnersContext } from './providers/PartnersProvider';
import { CategoryMaterialsContext } from './providers/CategoryMaterialsProvider';
import { MaterialsContext } from './providers/MaterialsProvider';
import { ShopsContext } from './providers/ShopsProvider';
import { OffersContext } from './providers/OffersProvider';
import { OffersProductsContext } from './providers/OffersProductsProvider';
import { MeasurementUnitsContext } from './providers/MeasurementUnitsProvider';

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
};

export const useUsersContext = () => {
  const context = useContext(UsersContext);
  if (context === undefined) {
    throw new Error('useUsersContext must be used within a UsersProvider');
  }
  return context;
};

export const useReportsContext = () => {
  const context = useContext(ReportsContext);
  if (context === undefined) {
    throw new Error('useReportsContext must be used within a ReportsProvider');
  }
  return context;
};

export const useCurrencyContext = () => {
  const context = useContext(CurrencyContext);
  if (context === undefined) {
    throw new Error('useCurrencyContext must be used within a CurrencyContext');
  }
  return context;
};

export const useShopsContext = () => {
  const context = useContext(ShopsContext);
  if (context === undefined) {
    throw new Error('useShopsContext must be used within a useShopsContext');
  }
  return context;
};

export const usePartnersContext = () => {
  const context = useContext(PartnersContext);
  if (context === undefined) {
    throw new Error('usePartnersContext must be used within a PartnersContext');
  }
  return context;
};

export const useCategoryMaterialsContext = () => {
  const context = useContext(CategoryMaterialsContext);
  if (context === undefined) {
    throw new Error('useCategoryMaterialsContext must be used within a CategoryMaterialsContext');
  }
  return context;
};

export const useMaterialsContext = () => {
  const context = useContext(MaterialsContext);
  if (context === undefined) {
    throw new Error('useMaterialsContext must be used within a MaterialsContext');
  }
  return context;
};
export const useOffersContext = () => {
  const context = useContext(OffersContext);
  if (context === undefined) {
    throw new Error('useOffersContext must be used within a OffersContext');
  }
  return context;
};

export const useOffersProductsContext = () => {
  const context = useContext(OffersProductsContext);
  if (context === undefined) {
    throw new Error('useOffersProductsContext must be used within a OffersProductsContext');
  }
  return context;
};

export const useMeasurementUnitsContext = () => {
  const context = useContext(MeasurementUnitsContext);
  if (context === undefined) {
    throw new Error('useMeasurementUnitsContext must be used within a MeasurementUnitsContext');
  }
  return context;
};
