import { FC, useState, useEffect } from 'react';

import moment from 'moment';

import { useMaterialsContext, useCurrencyContext, useMeasurementUnitsContext } from '../../../context/context';

import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

type Props = {
  product: any;
  newProductData: any;
  setNewProductData: any;
  errors?: any;
  isEdit: boolean;
  offer: any;
};

const SubArticleForm: FC<Props> = ({ product, errors, isEdit, newProductData, setNewProductData, offer }) => {
  const { searchMaterials } = useMaterialsContext();
  const { getCurrency, currency } = useCurrencyContext();
  const { getMeasurementUnits, measurementUnits } = useMeasurementUnitsContext();
  const [selectedDate, setSelectedDate] = useState(new Date(newProductData.date));
  const [transport, setTransport] = useState(0);
  const [validityDateMaterial, setValidityDateMaterial] = useState(new Date());

  useEffect(() => {
    setValidityDateMaterial(new Date(offer?.validity_date));
    fetchData();

    if (isEdit) {
      setNewProductData({
        material_id: product.material_id,
        unit_id: product.unit_id,
        currency_id: product.currency_id,
        date: product.date,
        price: product.price,
        status: product.status,
        id: product.id,
        price_tarif_two: product.price_tarif_two,
        price_tarif_per_km: product.price_tarif_per_km,
        notes: product.notes,
        validity_date: product.validity_date,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    getMeasurementUnits();
    getCurrency({ date: newProductData.date });
  };

  const onHandleChange = (e: any) => {
    setNewProductData({ ...newProductData, [e.target.name]: e.target.value });
  };

  const onHandleSelect = (item: any, target: any) => {
    setNewProductData({ ...newProductData, [target.name]: item.value, [`${target.name}Label`]: item.label });
    if (item.transport === 1) {
      setTransport(1);
    }
  };

  const onChangeDate = async (value: any) => {
    const date = moment(value).format('YYYY-MM-DD');
    setSelectedDate(value);
    setNewProductData({ ...newProductData, date: date });
    await getCurrency({ date: date });
  };

  const onChangeValidityDateMaterial = (value: any) => {
    const date = moment(value).format('YYYY-MM-DD');
    setValidityDateMaterial(value);
    setNewProductData({ ...newProductData, validity_date: date });
  };

  const onHandleSearchMaterials = (inputValue: string) =>
    new Promise<any>(async resolve => {
      const materials = await searchMaterials({ q: inputValue });

      resolve(
        materials?.map((q: any) => ({
          label: q.level_one_name + ' -> ' + q.level_two_name + ' -> ' + q.level_three_name,
          value: q.level_three_id,
          transport: q.transport,
        })) || []
      );
    });

  const defaultMaterial = isEdit &&
    product?.material && [
      {
        label: product?.material?.name,
        value: product?.material?.id,
      },
    ];
  const defaultMaterialLabel =
    isEdit && product?.material && product?.material_select
      ? product.material_select.level_one_name +
        ' -> ' +
        product.material_select.level_two_name +
        ' -> ' +
        product.material_select.level_three_name
      : '';

  const defaultUnit =
    isEdit && product?.unit_id
      ? {
          label: product?.measurement_unit?.name + '(' + product?.measurement_unit?.symbol + ')',
          value: product?.measurement_unit?.id,
        }
      : undefined;

  const defaultCurrency =
    isEdit && product?.currency_id ? { label: product?.currency?.symbol, value: product?.currency?.id } : undefined;

  return (
    <div className='p-4 mb-5 rounded d-flex align-items-center'>
      <div className='form w-100 d-flex flex-wrap'>
        <div
          className='col-sm-12 mb-7 px-2'
          data-bs-toggle='tooltip'
          title={newProductData.material_idLabel ?? defaultMaterialLabel}>
          <label className='fw-bold fs-6 mb-2'>Material</label>
          <AsyncSelect
            onChange={onHandleSelect}
            loadOptions={onHandleSearchMaterials}
            value={isEdit ? defaultMaterial[0] : product.material_id}
            placeholder='Material'
            name='material_id'
            isDisabled={isEdit && product?.id}
            defaultOptions={defaultMaterial}
            className='form-control-async-select'
          />
          {errors?.[`material_id`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`material_id`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-6 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Unitate de masura</label>
          <Select
            onChange={onHandleSelect}
            options={measurementUnits?.map((q: any) => ({ label: q.name + '(' + q.symbol + ')', value: q.id }))}
            placeholder='Unitate de masura'
            name='unit_id'
            isDisabled={isEdit && product?.id}
            value={isEdit && product?.measurement_unit ? defaultUnit : product.unit_id}
            className='form-control-async-select'
          />
          {errors?.[`unit_id`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`unit_id`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-3 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Data</label>
          <DatePicker
            selected={selectedDate}
            onChange={onChangeDate}
            value={newProductData.date}
            placeholderText='Data'
            dateFormat='dd/MM/yyyy'
            maxDate={new Date()}
            className='form-control-date-piker'
          />
          {errors?.[`date`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`date`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-3 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Valuta</label>
          <Select
            onChange={onHandleSelect}
            options={currency?.data?.map((q: any) => ({ label: q.symbol, value: q.id }))}
            placeholder='Valuta'
            name='currency_id'
            defaultValue={defaultCurrency}
            isDisabled={isEdit ? false : !selectedDate}
            className='form-control-async-select'
          />
          {errors?.[`currency_id`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`currency_id`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-sm-6 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>{transport || product?.material?.transport ? 'Pauschal' : 'Pret'}</label>
          <input
            placeholder={transport || product?.material?.transport ? 'Pauschal' : 'Pret'}
            type='number'
            step='any'
            min='0'
            name='price'
            className='form-control form-control-solid mb-3 mb-lg-0'
            autoComplete='off'
            value={newProductData.price}
            onChange={onHandleChange}
          />
          {errors?.[`price`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`price`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        {transport || product?.material?.transport ? (
          <>
            <div className='col-sm-3 mb-7 px-2'>
              <label className='fw-bold fs-6 mb-2'>Pauschal 2</label>
              <input
                placeholder='Pauschal 2'
                type='number'
                step='any'
                min='0'
                name='price_tarif_two'
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                value={newProductData.price_tarif_two}
                onChange={onHandleChange}
              />
              {errors?.[`price_tarif_two`] && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors?.[`price_tarif_two`]?.join(' ')}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-sm-3 mb-7 px-2'>
              <label className='fw-bold fs-6 mb-2'>Tarif (per km)</label>
              <input
                placeholder='Tarif (per km)'
                type='number'
                step='any'
                min='0'
                name='price_tarif_per_km'
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
                value={newProductData.price_tarif_per_km}
                onChange={onHandleChange}
              />
              {errors?.[`price_tarif_per_km`] && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors?.[`price_tarif_per_km`]?.join(' ')}</span>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          ''
        )}
        <div className='col-sm-6 mb-7 px-2'>
          <label className='fw-bold fs-6 mb-2'>Observatii</label>
          <textarea
            value={newProductData.notes}
            onChange={onHandleChange}
            name='notes'
            id='notes'
            className='form-control form-control-solid mb-3 mb-lg-0'
            autoComplete='off'
          />
          {errors?.[`notes`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`notes`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
        <div className=' w-50 px-2 mb-7'>
          <label className='fw-bold fs-6 mb-2'>Data Valabilitate Material:</label>
          <DatePicker
            selected={validityDateMaterial}
            maxDate={new Date(offer.validity_date)}
            minDate={new Date(offer.issued_at)}
            onChange={onChangeValidityDateMaterial}
            className='form-control form-control-solid form-select-sm form-select mb-3 mb-lg-0'
          />
          {errors?.[`validity_date`] && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors?.[`validity_date`]?.join(' ')}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { SubArticleForm };
