import React, { useState } from 'react';
import { api } from '../api';

export const ShopsContext = React.createContext();

export const ShopsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [gridParams, setGridParams] = useState({
    page: 1,
    limit: 10,
  });
  const [shops, setShops] = useState({
    data: [],
  });
  const [cities, SetCities] = useState({
    data: [],
  });

  const addShop = async data => {
    return await api
      .post('/api/shop', data)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  const editShop = async (id, data) => {
    return await api
      .put(`/api/shop/${id}`, data)
      .then(response => {
        return { data: response.data };
      })
      .catch(error => {
        return { error };
      });
  };

  const cityRegionList = async query => {
    return await api
      .get(`/api/city-region-list`, { params: query })
      .then(response => {
        SetCities(response.data);
        return { data: response.data };
      })
      .catch(error => ({ error }));
  };

  const getShops = async query => {
    setLoading(true);
    return await api
      .get('/api/shop', {
        params: query,
      })
      .then(response => {
        setShops(response.data);
        setLoading(false);
        return { data: response.data };
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const getShopsForReportsFilters = async () => {
    setLoading(true);
    return await api
      .get('/api/shop-list')
      .then(response => {
        setShops(response.data);
        setLoading(false);
        return { data: response.data };
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  return (
    <ShopsContext.Provider
      value={{
        shops,
        cities,
        getShops,
        getShopsForReportsFilters,
        addShop,
        editShop,
        cityRegionList,
        gridData: {
          getData: getShops,
          isLoading: loading,
          data: shops?.data || [],
          links: shops?.links || [],
          params: gridParams,
          setParams: setGridParams,
          resetState: () => setShops({ data: [] }),
        },
      }}>
      {children}
    </ShopsContext.Provider>
  );
};
