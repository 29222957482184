import { useEffect, useState } from 'react';
import { DataGrid } from '../dataGrid/DataGrid';
import { CustomHeader } from '../dataGrid/CustomHeader';
import { Column } from 'react-table';
import { User } from '../apps/user-management/users-list/core/_models';
import { useReportsContext } from '../../../context/context';
import { useCurrencyContext } from '../../../context/context';

import { Filter } from '../filter/Filter';
import { GridSelectionHeader } from './GridSelectionHeader';
import { GridSelectionCell } from './GridSelectionCell';
import { ReportChart } from '../reportChart/ReportChart';

const ReportsTable = () => {
  const { gridData, getExcelReportFile, materialReports, chartReports, getReportDetailsForFilters } =
    useReportsContext();
  const { getCurrency, currency } = useCurrencyContext();
  const { params, setParams } = gridData;
  const [checkedReportsIds, setCheckedReportsIds] = useState<any>({ offers: [] });
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [downloadingXLSX, setDownloadingXLSX] = useState(false);
  const [exportDisabled, setExportDisabled] = useState(true);
  const [activeGrafic, setActiveGraph] = useState(false);
  const [loadingGraph, setLoadingGraph] = useState(false);

  const ReportsColumns: ReadonlyArray<Column<User>> = [
    {
      Header: props => (
        <GridSelectionHeader
          gridData={gridData}
          tableProps={props}
          checkedData={checkedReportsIds.offers}
          onCheckAll={(newData: any) =>
            setCheckedReportsIds({
              offers: newData,
            })
          }
        />
      ),
      id: 'selection',
      Cell: ({ ...props }) => (
        <GridSelectionCell
          id={props.data[props.row.index].id}
          allChecked={false}
          checked={!checkedReportsIds?.offers?.find((item: any) => props.row.original?.id === item?.id)}
          onCheckReport={() => onHandleCheckReport(props.row.original)}
        />
      ),
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Familie' params={params} setParams={setParams} />,
      id: 'material_categories_lvl_1_name',
      Cell: ({ ...props }) => props.data[props.row.index].material_categories_lvl_1_name,
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Sub-Familie' params={params} setParams={setParams} />,
      id: 'material_categories_lvl_2_name',
      Cell: ({ ...props }) => props.data[props.row.index].material_categories_lvl_2_name,
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Material' params={params} setParams={setParams} />,
      id: 'material_name',
      Cell: ({ ...props }) => <div style={{ minWidth: 250 }}>{props.data[props.row.index].material_name}</div>,
      width: 250,
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Partener' params={params} setParams={setParams} />,
      id: 'partners_company_name',
      Cell: ({ ...props }) => (
        <div style={{ minWidth: 150 }}>{props.data[props.row.index].offer.partner.company_name}</div>
      ),
      width: 250,
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Cod-Material' params={params} setParams={setParams} />,
      id: 'material_code',
      Cell: ({ ...props }) => props.data[props.row.index].material_code,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Status' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'status',
      Cell: ({ ...props }) => props.data[props.row.index].status,
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='ID oferta'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'offers_number',
      Cell: ({ ...props }) => props.data[props.row.index].offer.number,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Data' className='min-w-120px' params={params} setParams={setParams} />
      ),
      id: 'date',
      Cell: ({ ...props }) => props.data[props.row.index].offer.created_at.slice(0, 10),
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='U.M.' className='min-w-120px' params={params} setParams={setParams} />
      ),
      id: 'unit_name',
      Cell: ({ ...props }) => props.data[props.row.index].unit.symbol ?? props.data[props.row.index].unit.name,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Pret' className='min-w-120px' params={params} setParams={setParams} />
      ),
      id: 'priceConverted',
      Cell: ({ ...props }) => (
        <>
          {props.data[props.row.index].material_transport === 1 && params.shops
            ? `${Number(props.data[props.row.index].priceConverted).toFixed(2)} ${
                props.data[props.row.index].priceConvertedCurrency
              }`
            : props.data[props.row.index].material_transport === 1
            ? 'N/A'
            : null}
          {props.data[props.row.index].material_transport === 0 &&
            `${Number(props.data[props.row.index].priceConverted).toFixed(2)} ${
              props.data[props.row.index].priceConvertedCurrency
            }`}
        </>
      ),
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Pret Original'
          className='min-w-120px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'price',
      Cell: ({ ...props }) => (
        <>
          {props.data[props.row.index].material_transport === 1 && params.shops
            ? `${props.data[props.row.index].price} ${props.data[props.row.index].currency?.symbol}`
            : props.data[props.row.index].material_transport === 1
            ? 'N/A'
            : null}
          {props.data[props.row.index].material_transport === 0 &&
            `${props.data[props.row.index].price} ${props.data[props.row.index].currency?.symbol}`}
        </>
      ),
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Observatii' params={params} setParams={setParams} />,
      id: 'notes',
      Cell: ({ ...props }) => props.data[props.row.index].notes,
    },
  ];

  useEffect(() => {
    getCurrency();
    getReportDetailsForFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleCheckReport = (offer: any) => {
    if (!checkedReportsIds?.offers?.find((item: any) => offer?.id === item?.id)) {
      setCheckedReportsIds({
        offers: [...checkedReportsIds?.offers, offer],
      });
    } else {
      setCheckedReportsIds({ offers: checkedReportsIds?.offers?.filter((item: any) => item.id !== offer.id) });
    }
  };

  const onHandleFilter = (data: any) => {
    const newData = {
      shops: data?.shops,
      partners: data?.partners,
      currencyDate: data?.currencyDate,
      products: data?.selectedProducts,
      mesurementUnits: data?.selectedMesurementUnits?.toString(),
      dateFrom: data?.dateFrom,
      dateTo: data?.dateTo,
      priceFrom: data?.priceFrom,
      priceTo: data?.priceTo,
      currency: data?.selectedCurrency?.label || params?.currency,
      status: data?.status,
      material_category_id: data?.material_category_id,
      material_id: data?.material_id,
      excludeProducts:
        checkedReportsIds?.offers?.length > 0 ? checkedReportsIds?.offers?.map((i: any) => i.id).join(',') : '',
    };

    setActiveGraph(false);
    setParams({ ...params, ...newData, page: 1 });
  };

  const onHandleDownloadReport = (type: String) => {
    if (type === 'PDF') {
      setDownloadingPDF(true);
    } else {
      setDownloadingXLSX(true);
    }
    getExcelReportFile({ ...params, type: type }).finally(() => {
      setDownloadingPDF(false);
      setDownloadingXLSX(false);
    });
  };

  const onHandleGrafic = () => {
    const reportDetails = {
      currency: params?.currency,
      currencyDate: params?.currencyDate,
      partnerId: params?.partners,
      unitId: params?.mesurementUnits,
      offers: [
        {
          materialId: params?.material_id,
        },
      ],
    };

    setLoadingGraph(true);
    setActiveGraph(false);
    materialReports(reportDetails).finally(() => {
      setLoadingGraph(false);
      setActiveGraph(true);
    });
  };

  return (
    <div>
      <DataGrid
        columns={ReportsColumns}
        useDataContext={useReportsContext}
        gridDetails={activeGrafic ? <ReportChart data={chartReports} /> : null}
        aditionalActions={
          <div>
            <Filter
              onFilterReports={onHandleFilter}
              setExportDisabled={setExportDisabled}
              params={params}
              currencies={currency.data}
            />
            <button
              className='btn btn-light-primary me-3'
              disabled={!params.material_id || !params?.mesurementUnits}
              onClick={onHandleGrafic}>
              {!loadingGraph && 'Grafic material'}
              {loadingGraph && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              className='btn btn-light-primary me-3'
              disabled={exportDisabled}
              onClick={() => onHandleDownloadReport('PDF')}>
              PDF
              {downloadingPDF && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
            </button>
            <button
              className='btn btn-light-primary me-3'
              disabled={exportDisabled}
              onClick={() => onHandleDownloadReport('XLSX')}>
              XLSX
              {downloadingXLSX && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
            </button>
          </div>
        }
      />
    </div>
  );
};

export { ReportsTable };
