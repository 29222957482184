import { FC, useEffect, useState } from 'react';
import { MenuComponent } from '../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../_metronic/helpers';
import { MultiSelect } from 'react-multi-select-component';
import moment from 'moment';

import {
  useCurrencyContext,
  useMeasurementUnitsContext,
  useShopsContext,
  useCategoryMaterialsContext,
  usePartnersContext,
} from '../../../context/context';

import Select from 'react-select';
import DatePicker from 'react-datepicker';

type Props = {
  onFilterReports: any;
  params?: any;
  currencies: any;
  setExportDisabled: (a: boolean) => void;
};

const StatusOptions = [
  {
    label: 'Offer',
    value: 'OFFER',
  },
  {
    label: 'Contract',
    value: 'CONTRACT',
  },
  {
    label: 'Expired Contract',
    value: 'EXPIRED_CONTRACT',
  },
  {
    label: 'Terminated Contract',
    value: 'TERMINATED_CONTRACT',
  },
];

const Filter: FC<Props> = ({ onFilterReports, params, currencies, setExportDisabled }) => {
  const { getCurrency, currency } = useCurrencyContext();
  const { getShopsForReportsFilters } = useShopsContext();
  const { getMeasurementUnits, measurementUnits } = useMeasurementUnitsContext();
  const { getMaterialDetailsFilter, getMultipleCategoryMaterialDetalisForReportsFilters } =
    useCategoryMaterialsContext();
  const { getPartnersForReportsFilters } = usePartnersContext();

  const [partners, setPartners] = useState([]);
  const [shops, setShops] = useState([]);

  const [filterParams, setFilterParams] = useState({
    shops: params?.shop || null,
    currencyDate: moment().format('YYYY-MM-DD'),
    selectedProducts: params?.selectedProducts || [],
    selectedMesurementUnits: params?.selectedMesurementUnits || [],
    priceFrom: params?.priceFrom || 0,
    priceTo: params?.maxPrice,
    dateFrom: params?.minDate,
    dateTo: params?.dateTo || moment().format('YYYY-MM-DD'),
    selectedCurrency: { label: 'RON', id: 2482 },
    status: params?.status || null,
    material_category_id: params?.material_category_id || null,
    material_id: params?.material_id || null,
    partners: params.partners || null,
  });

  const [firstLevelOptions, setFirstLevelOptions] = useState([]);
  const [secondFilterOptions, setSecondFilterOptions] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [familiesItem, setFamiliesItem] = useState<any>([]);
  const [subFamiliesItem, setSubFamiliesItem] = useState<any>([]);
  const [materialItem, setMaterialItem] = useState<any>([]);
  const [partnerItem, setPartnerItem] = useState<any>([]);
  const [statusItem, setStatusItem] = useState<any>([]);
  const [shopsItem, setShopsItem] = useState<any>([]);

  const { selectedMesurementUnits, priceFrom, dateFrom, dateTo, priceTo, selectedCurrency, currencyDate } =
    filterParams;

  useEffect(() => {
    MenuComponent.reinitialization();
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilterParams({ ...filterParams, priceTo: params?.maxPrice, dateFrom: params?.minDate });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const fetchData = async () => {
    await getMeasurementUnits();
    await getMultipleCategoryMaterialDetalisForReportsFilters({ level: 1 }).then((res: any) => {
      setFirstLevelOptions(res.data.data);
    });
    await getShopsForReportsFilters().then((res: any) => {
      setShops(res.data);
    });
    await getPartnersForReportsFilters().then((res: any) => {
      setPartners(res);
    });
  };

  const resetData = () => {
    const initialParams = {
      shops: null,
      currencyDate: moment().format('YYYY-MM-DD'),
      selectedProducts: null,
      selectedMesurementUnits: null,
      priceFrom: 0,
      priceTo: params?.maxPrice,
      dateFrom: params?.minDate,
      dateTo: moment().format('YYYY-MM-DD'),
      selectedCurrency: { label: 'RON', id: 2482 },
      status: null,
      material_category_id: null,
      partners: null,
      material_id: null,
    };

    const dateFrom: any = document.getElementById('dateFrom');
    dateFrom.value = null;

    setFamiliesItem([]);
    setSubFamiliesItem([]);
    setMaterialItem([]);
    setPartnerItem([]);
    setShopsItem([]);

    setFilterParams(initialParams);
    onFilterReports({
      ...initialParams,
      currencyDate: moment().format('YYYY-MM-DD'),
      currency: 'RON',
    });

    setExportDisabled(true);
  };

  const filterData = () => {
    onFilterReports(filterParams);

    if (filterParams.shops && filterParams.partners) {
      setExportDisabled(false);
    } else {
      setExportDisabled(true);
    }
  };

  const onChangeFilterParams = (e: any) => {
    setFilterParams({ ...filterParams, [e.target.name]: e.target.value });
  };

  const onChangeDate = async (value: any) => {
    const date = moment(value).format('YYYY-MM-DD');
    setFilterParams({ ...filterParams, currencyDate: date });
    await getCurrency({ date: date });
  };

  const onHandleSelect = (item: any, target: any) => {
    setFilterParams({ ...filterParams, [target.name]: item });
  };

  const getShopsOptions = () => {
    return shops.map((item: any) => ({ label: item.name, value: item.id }));
  };

  const getPartnersOptions = () => {
    return partners.map((item: any) => ({ label: item.company_name, value: item.id }));
  };

  const getFamilyOptions = () => {
    return firstLevelOptions?.map((item: any) => ({ label: item.name, value: item.id }));
  };

  const getSubFamilyOptions = () => {
    return secondFilterOptions?.map((item: any) => ({ label: item.name, value: item.id }));
  };

  const getMaterialOptions = () => {
    return materialOptions?.map((item: any) => ({ label: item.name, value: item.id }));
  };

  const onChangeFamilySelect = (value: any) => {
    setFamiliesItem(value);
    setPartnerItem([]);

    if (value.length) {
      getMultipleCategoryMaterialDetalisForReportsFilters({
        parent_id: value.map((q: any) => q.value).join(','),
        level: 2,
      }).then((res: any) => {
        setSecondFilterOptions(res.data.data ?? []);
      });

      getPartnersForReportsFilters({ materialCategoryId: value.map((q: any) => q.value).join(',') }).then(
        (res: any) => {
          setPartners(res);
        }
      );

      setFilterParams({ ...filterParams, material_category_id: value.map((q: any) => q.value).join(',') });
    } else {
      setSubFamiliesItem([]);
      setSecondFilterOptions([]);
      setMaterialItem([]);
      setMaterialOptions([]);
      setPartnerItem([]);
      setPartners([]);
      setFilterParams({ ...filterParams, material_category_id: '', material_id: null });

      getPartnersForReportsFilters().then((res: any) => {
        setPartners(res);
      });
    }
  };

  const onChangeSubFamilySelect = (value: any) => {
    setSubFamiliesItem(value);
    setPartnerItem([]);

    setFilterParams({ ...filterParams, material_category_id: value.map((q: any) => q.value).join(',') });

    if (value.length) {
      getMaterialDetailsFilter(value.map((q: any) => q.value).join(',')).then((res: any) => {
        setMaterialOptions(res.data ?? []);
      });

      getPartnersForReportsFilters({ materialCategoryId: value.map((q: any) => q.value).join(',') }).then(
        (res: any) => {
          setPartners(res);
        }
      );
    } else {
      setMaterialOptions([]);
      setMaterialItem([]);
      setFilterParams({ ...filterParams, material_id: null });

      getPartnersForReportsFilters({ materialCategoryId: familiesItem.map((q: any) => q.value).join(',') }).then(
        (res: any) => {
          setPartners(res);
        }
      );
    }
  };

  const onChangeMaterialSelect = (value: any) => {
    setPartnerItem([]);
    setMaterialItem(value);
    setFilterParams({ ...filterParams, material_id: value.map((q: any) => q.value).join(',') });

    if (value) {
      getPartnersForReportsFilters({ materialId: value.map((q: any) => q.value).join(',') }).then((res: any) => {
        setPartners(res);
      });
    } else {
      getPartnersForReportsFilters({ materialCategoryId: subFamiliesItem.map((q: any) => q.value).join(',') }).then(
        (res: any) => {
          setPartners(res);
        }
      );
    }
  };

  const onChangePartnerSelect = (value: any) => {
    setPartnerItem(value);
    setFilterParams({ ...filterParams, partners: value.map((q: any) => q.value).join(',') });
  };

  const onChangeStatusSelect = (value: any) => {
    setStatusItem(value);
    setFilterParams({ ...filterParams, status: value.map((q: any) => q.value).join(',') });
  };

  const onChangeShopsSelect = (shop: any) => {
    setShopsItem(shop);
    setFilterParams({ ...filterParams, shops: shop.value });
  };

  return (
    <>
      <button
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'>
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filtre
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-700px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filtre</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5 d-flex flex-wrap' data-kt-user-table-filter='form'>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Familii</label>
            <MultiSelect
              onChange={onChangeFamilySelect}
              options={getFamilyOptions()}
              labelledBy='Familii'
              dropdown-heading
              value={familiesItem}
              className='form-control-async-select-multiple'
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Sub-Familii</label>
            <MultiSelect
              onChange={onChangeSubFamilySelect}
              options={getSubFamilyOptions()}
              labelledBy='Sub Familii'
              dropdown-heading
              value={subFamiliesItem}
              className='form-control-async-select-multiple'
            />
          </div>
          <div className='mb-10 w-100 me-8'>
            <label className='form-label fs-6 fw-bold'>Material</label>
            <MultiSelect
              onChange={onChangeMaterialSelect}
              options={getMaterialOptions()}
              labelledBy='Material'
              dropdown-heading
              value={materialItem}
              className='form-control-async-select-multiple'
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Partener</label>
            <MultiSelect
              onChange={onChangePartnerSelect}
              options={getPartnersOptions()}
              dropdown-heading
              value={partnerItem}
              labelledBy='Partener'
              className='form-control-async-select-multiple'
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Magazin</label>
            <Select
              onChange={onChangeShopsSelect}
              options={getShopsOptions()}
              placeholder='Magazin'
              name='selectedShop'
              value={shopsItem}
              className='form-control-async-select'
              data-control='shop'
            />
          </div>
          <div className='separator border-gray-400 me-8 mb-10 w-100'></div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Data</label>
            <DatePicker
              onChange={onChangeDate}
              selected={currencyDate ? new Date(currencyDate) : null}
              placeholderText='Data'
              dateFormat='dd/MM/yyyy'
              maxDate={new Date()}
              className='form-control-date-piker'
              data-control='currency'
              data-hide-search='true'
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Valuta</label>
            <Select
              onChange={onHandleSelect}
              options={currency?.data?.map((q: any) => ({ label: q.symbol, value: q.id }))}
              placeholder='Valuta'
              name='selectedCurrency'
              value={selectedCurrency}
              className='form-control-async-select'
              data-control='currency'
              data-hide-search='true'
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Status</label>
            <MultiSelect
              onChange={onChangeStatusSelect}
              options={StatusOptions?.map((q: any) => ({ label: q.label, value: q.value }))}
              dropdown-heading
              value={statusItem}
              labelledBy='Status'
              className='form-control-async-select-multiple'
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Unitate de masura</label>
            <MultiSelect
              hasSelectAll={false}
              options={measurementUnits?.map((q: any) => ({
                label: q.name,
                value: q.id,
              }))}
              value={measurementUnits
                ?.filter((q: any) => selectedMesurementUnits?.find((selected: any) => selected === q.id))
                .map((q: any) => ({
                  label: q.name,
                  value: q.id,
                }))}
              onChange={(value: any) =>
                setFilterParams({ ...filterParams, selectedMesurementUnits: value.map((q: any) => q.value) })
              }
              labelledBy='Select'
              className='form-control-async-select-multiple'
            />
          </div>

          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>De la data</label>
            <input
              id='dateFrom'
              className='form-control form-control-solid mb-3 mb-lg-0'
              type='date'
              lang='en'
              name='dateFrom'
              onChange={onChangeFilterParams}
              value={dateFrom}
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Pina la data</label>
            <input
              id='dateTo'
              className='form-control form-control-solid mb-3 mb-lg-0 date'
              type='date'
              lang='en'
              name='dateTo'
              onChange={onChangeFilterParams}
              defaultValue={dateTo}
            />
          </div>

          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>De la pretul</label>
            <input
              placeholder='De la pretul'
              type='text'
              name='priceFrom'
              className='form-control form-control-solid mb-3 mb-lg-0'
              value={priceFrom}
              onChange={onChangeFilterParams}
            />
          </div>
          <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Pina la pretul</label>
            <input
              placeholder='Pina la pretul'
              type='text'
              name='priceTo'
              className='form-control form-control-solid mb-3 mb-lg-0'
              value={priceTo}
              onChange={onChangeFilterParams}
            />
          </div>

          {/* <div className='mb-10 w-md-300px me-6'>
            <label className='form-label fs-6 fw-bold'>Currency</label>
            <select
              placeholder='Currency'
              name='currency'
              data-control='currency'
              data-hide-search='true'
              className='form-control form-control-solid form-select-sm form-select mb-3 mb-lg-0'
              onChange={onSlelectCurrency}
              value={currency}>
              <option value=''></option>
              {currencies?.map((item: { name: string; id: number }, index: number) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div> */}
          <div className='d-flex align-items-center '>
            <button
              type='button'
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'>
              Reseteaza
            </button>
            <button
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'>
              Aplica
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { Filter };
