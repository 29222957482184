import { useEffect, useState, FC } from 'react';
import { DataGrid } from '../dataGrid/DataGrid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomHeader } from '../dataGrid/CustomHeader';
import { Column } from 'react-table';
import { User } from '../apps/user-management/users-list/core/_models';
import { useCategoryMaterialsContext } from '../../../context/context';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import { MaterialMultipleCategoryFilter } from '../filter/MaterialMultipleCategoryFilter';

import { CategoryMaterialAddEditModal } from './CategoryMaterialAddEditModal';

type Props = {
  level: number;
};

const CategoryMaterialsTable: FC<Props> = ({ level }) => {
  const { gridData, getCategoryMaterial } = useCategoryMaterialsContext();
  const { params, setParams, getData } = gridData;
  const [categoryMaterialData, setCategoryMaterialData] = useState<any>({});
  const [itemInfo, setItemInfo] = useState<any>(null);
  const [loadingItemInfo, setLoadingItemInfo] = useState(true);
  const [linkParams] = useSearchParams();
  const navigate = useNavigate();

  const [openCategoryMaterialModal, setOpenCategoryMaterialModal] = useState(false);

  useEffect(() => {
    setParams({ ...params, parent_id: linkParams.get('parent_id'), level: level });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkParams.get('parent_id'), level]);

  useEffect(() => {
    (async () => {
      if (linkParams.get('parent_id') && linkParams.get('parent_id') !== null) {
        const categoryMaterial = await getCategoryMaterial(linkParams.get('parent_id'));
        setItemInfo(categoryMaterial.data);
        setLoadingItemInfo(false);
      } else {
        setItemInfo(null);
        setLoadingItemInfo(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkParams]);

  const getColumns = () => {
    const columns = [];
    switch (level) {
      case 2:
        columns.push({
          Header: (props: any) => (
            <CustomHeader
              tableProps={props}
              title='Familie'
              className='min-w-50px'
              params={params}
              setParams={setParams}
            />
          ),
          id: 'parent_name',
          Cell: ({ ...props }) => props.data[props.row.index]?.parent?.name,
        });
        break;
      case 3:
        columns.push({
          Header: (props: any) => (
            <CustomHeader
              tableProps={props}
              title='Familie'
              className='min-w-50px'
              params={params}
              setParams={setParams}
            />
          ),
          id: 'familie',
          Cell: ({ ...props }) => props.data[props.row.index]?.parent?.parent?.name,
        });
        columns.push({
          Header: (props: any) => (
            <CustomHeader
              tableProps={props}
              title='Sub-Familie'
              className='min-w-50px'
              params={params}
              setParams={setParams}
            />
          ),
          id: 'sub-familie',
          Cell: ({ ...props }) => props?.data[props.row.index]?.parent?.name,
        });
        break;
    }
    return columns;
  };

  const CategoryMaterialColumns: ReadonlyArray<Column<User>> = [
    ...getColumns(),

    {
      Header: props => (
        <CustomHeader tableProps={props} title='Nume' className='min-w-50px' params={params} setParams={setParams} />
      ),
      id: 'name',
      Cell: ({ ...props }) => props.data[props.row.index].name,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Code' className='min-w-50px' params={params} setParams={setParams} />
      ),
      id: 'code',
      Cell: ({ ...props }) => props.data[props.row.index].code,
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />,
      id: 'actions',
      Cell: ({ ...props }) => {
        return (
          <div>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => onWatchNextLevel(props.row.original.id)}>
              <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3' />
            </button>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => onOpenEditCategoryMaterial(props.row.original.id)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
          </div>
        );
      },
    },
  ];

  const onWatchNextLevel = (id: any) => {
    switch (level) {
      case 1:
        navigate(`/sub-families?parent_id=${id}`);
        break;
      case 2:
        navigate(`/sub-articles?parent_id=${id}`);
        break;
      default:
        break;
    }
  };

  const onCloseCategoryMaterialModal = () => {
    getData(params);
    setOpenCategoryMaterialModal(false);
    setCategoryMaterialData({});
  };

  const onOpenEditCategoryMaterial = async (id: any) => {
    const categoryMaterial = await getCategoryMaterial(id);
    setCategoryMaterialData(categoryMaterial.data);
    setOpenCategoryMaterialModal(true);
  };

  const onHandleFilter = (data: any) => {
    setParams({ ...params, parent_id: data.parent_id });
    getData({ ...params, parent_id: data.parent_id, page: 1 });
  };

  const getBreadcrumb = () => {
    switch (level) {
      case 1:
        if (!itemInfo) {
          return [
            {
              title: 'Familii',
              path: '/materials',
              isActive: true,
              isSeparator: false,
            },
          ];
        }
        break;
      case 2:
        if (itemInfo && !itemInfo.parent) {
          return [
            {
              title: 'Familii',
              path: '/materials',
              isActive: false,
              isSeparator: false,
            },
            {
              title: '',
              path: ``,
              isActive: false,
              isSeparator: true,
            },
            {
              title: itemInfo?.name,
              path: `/sub-families?parent_id=${itemInfo?.id}`,
              isActive: true,
              isSeparator: false,
            },
          ];
        }
        break;
      case 3:
        if (itemInfo && itemInfo.parent) {
          return [
            {
              title: 'Familii',
              path: '/materials',
              isActive: false,
              isSeparator: false,
            },
            {
              title: '',
              path: ``,
              isActive: false,
              isSeparator: true,
            },
            {
              title: itemInfo.parent?.name,
              path: `/sub-families?parent_id=${itemInfo.parent?.id}`,
              isActive: false,
              isSeparator: false,
            },
            {
              title: '',
              path: ``,
              isActive: false,
              isSeparator: true,
            },
          ];
        }
        break;
      default:
        break;
    }
  };

  const addTitle = level === 1 ? 'Add Familie' : level === 2 ? 'Add Sub-Familie' : '';

  if (loadingItemInfo) return <p>LOADING</p>;

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumb()} />

      <div className=''>
        <div className=''>
          <div>
            {openCategoryMaterialModal && (
              <CategoryMaterialAddEditModal
                openModal={openCategoryMaterialModal}
                onClose={onCloseCategoryMaterialModal}
                categoryMaterial={categoryMaterialData}
                level={level}
              />
            )}
            <DataGrid
              columns={CategoryMaterialColumns}
              useDataContext={useCategoryMaterialsContext}
              addButton={{
                label: addTitle,
                action: () => setOpenCategoryMaterialModal(true),
              }}
              aditionalActions={
                level !== 1 ? (
                  <div>
                    <MaterialMultipleCategoryFilter onFilterMaterials={onHandleFilter} params={params} level={level} />
                  </div>
                ) : (
                  ''
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { CategoryMaterialsTable };
