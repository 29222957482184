import clsx from 'clsx';
import { FC } from 'react';

type Props = {
  className?: string;
  title?: string;
  tableProps: any;
  params?: any;
  setParams?: (params: any) => void;
  filters?: any;
  disableSorting?: boolean;
};
const CustomHeader: FC<Props> = ({ className, title, tableProps, params, setParams, filters, disableSorting }) => {
  const id = tableProps.column.id;

  return (
    <th {...tableProps.column.getHeaderProps()} style={{ cursor: 'pointer' }}>
      <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
        {disableSorting === true ? (
          <span className={className + ' text-dark '}>{title}</span>
        ) : (
          <span
            className={clsx(
              className + ' text-dark ',
              params?.orderBy === id &&
                params?.orderDirection !== undefined &&
                `table-sort-${params?.orderDirection === 'asc' ? 'asc' : 'desc'}`
            )}
            onClick={() => {
              if (setParams) {
                setParams({
                  ...params,
                  orderDirection: params?.orderDirection === 'asc' ? 'desc' : 'asc',
                  orderBy: id,
                });
              }
            }}>
            {title}
          </span>
        )}

        {filters && filters}
      </div>
    </th>
  );
};

export { CustomHeader };
