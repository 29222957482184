import React, { useRef, useState } from 'react';
import { api } from '../api';

export const CurrencyContext = React.createContext();

export const CurrencyProvider = ({ children }) => {
  const tmpCurrency = useRef([]);
  const [loading, setLoading] = useState(false);
  const [gridParams, setGridParams] = useState({
    page: 1,
    limit: 10,
  });
  const [currency, setCurrency] = useState({
    data: [],
  });

  const addCurrency = async data => {
    return await api
      .post('/api/currency', data)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  const editCurrency = async (id, data) => {
    return await api
      .put(`/api/currency/${id}`, data)
      .then(response => {
        return { data: response.data };
      })
      .catch(error => {
        return { error };
      });
  };

  const deleteCurrency = async id => {
    return await api
      .delete(`/api/currency/${id}`)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  const getCurrency = async query => {
    if (query?.date) {
      const existInTmp = tmpCurrency.current.find(q => q.date === query.date);
      if (existInTmp) {
        return new Promise((resolve, reject) => {
          resolve(existInTmp.data);
        });
      }
    }

    setLoading(true);
    return await api
      .get('/api/currency', {
        params: query,
      })
      .then(response => {
        setCurrency(response.data);

        tmpCurrency.current.push({
          date: query.date,
          data: response.data,
        });

        setLoading(false);
        return response.data;
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        getCurrency,
        addCurrency,
        editCurrency,
        deleteCurrency,
        gridData: {
          getData: getCurrency,
          isLoading: loading,
          data: currency?.data || [],
          links: currency?.links || [],
          params: gridParams,
          setParams: setGridParams,
          resetState: () => setCurrency({ data: [] }),
        },
      }}>
      {children}
    </CurrencyContext.Provider>
  );
};
