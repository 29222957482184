import React, { useState } from 'react';
import { api } from '../api';

export const MeasurementUnitsContext = React.createContext();

export const MeasurementUnitsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [gridParams, setGridParams] = useState({
    page: 1,
    limit: 10,
  });
  const [measurementUnits, setMeasurementUnits] = useState([]);

  const getMeasurementUnits = async () => {
    setLoading(true);
    return await api
      .get('/api/measurement-units')
      .then(response => {
        setMeasurementUnits(response.data);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  return (
    <MeasurementUnitsContext.Provider
      value={{
        measurementUnits,
        getMeasurementUnits,
        gridData: {
          getData: getMeasurementUnits,
          isLoading: loading,
          data: measurementUnits?.data || [],
          links: measurementUnits?.links || [],
          params: gridParams,
          setParams: setGridParams,
          resetState: () => setMeasurementUnits({ data: [] }),
        },
      }}>
      {children}
    </MeasurementUnitsContext.Provider>
  );
};
