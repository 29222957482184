import React, { useState } from 'react';
import { api } from '../api';

export const OffersProductsContext = React.createContext();

export const OffersProductsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [submitErrors, setSubmitErrors] = useState({});
  const [gridParams, setGridParams] = useState({
    page: 1,
    limit: 10,
  });
  const [offerProducts, setOffersProducts] = useState({
    data: [],
  });
  const [offerProduct, setOfferProduct] = useState({
    data: [],
  });

  const getOfferProducts = async query => {
    setLoading(true);
    return await api
      .get(`/api/offer/${window.location.pathname.replace(/\D/g, '')}/products`, {
        params: query,
      })
      .then(response => {
        setOffersProducts(response.data);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const getOfferProduct = async (id, productId) => {
    setSubmitErrors({});
    setLoading(true);
    return await api
      .get(`/api/offer/${id}/products/${productId}`)
      .then(response => {
        setOfferProduct(response.data);
        setLoading(false);
        return { data: response.data };
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const addOfferProduct = async (id, data) => {
    setSubmitErrors({});
    return await api
      .post(`/api/offer/${id}/products`, data)
      .then(response => ({ data: response.data }))
      .catch(error => {
        setSubmitErrors(error?.response?.data || {});
        return { error };
      });
  };

  const editOfferProduct = async (id, data, productId) => {
    setSubmitErrors({});
    return await api
      .put(`/api/offer/${id}/products/${productId}`, data)
      .then(response => response.data)
      .catch(error => {
        setSubmitErrors(error?.response?.data || {});
        return { error };
      });
  };

  const deleteOfferProduct = async (id, productId) => {
    return await api
      .delete(`/api/offer/${id}/products/${productId}`)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  return (
    <OffersProductsContext.Provider
      value={{
        offerProducts,
        offerProduct,
        getOfferProducts,
        getOfferProduct,
        addOfferProduct,
        editOfferProduct,
        deleteOfferProduct,
        submitErrors,
        gridData: {
          getData: getOfferProducts,
          isLoading: loading,
          data: offerProducts?.data || [],
          links: offerProducts?.links || [],
          params: gridParams,
          setParams: setGridParams,
          resetState: () => setOffersProducts({ data: [] }),
        },
      }}>
      {children}
    </OffersProductsContext.Provider>
  );
};
