/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { DealsTable } from '../../modules/deals/DealsTable';
import { ProductsTable } from '../../modules/deals/ProductsTable';

type Props = {
  level: number;
};

const DealsWrapper: FC<Props> = ({ level }) => {
  const [partner, setPartner] = useState<any>('');

  return <>{level === 1 ? <DealsTable setPartner={setPartner} /> : <ProductsTable partner={partner} />}</>;
};

export { DealsWrapper };
