import { useEffect, useState, FC } from 'react';
import { DataGrid } from '../dataGrid/DataGrid';
import { MaterialMultipleCategoryFilter } from '../filter/MaterialMultipleCategoryFilter';
import { useSearchParams } from 'react-router-dom';
import { CustomHeader } from '../dataGrid/CustomHeader';
import { Column } from 'react-table';
import { User } from '../apps/user-management/users-list/core/_models';
import { useMaterialsContext } from '../../../context/context';
import { useCategoryMaterialsContext } from '../../../context/context';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import Swal from 'sweetalert2';

import { MaterialAddEditModal } from './MaterialAddEditModal';

type Props = {
  level: number;
};

const MaterialsTable: FC<Props> = ({ level }) => {
  const { gridData, getMaterial, deleteMaterial } = useMaterialsContext();
  const { getCategoryMaterial } = useCategoryMaterialsContext();
  const { params, setParams, getData } = gridData;
  const [materialData, setMaterialData] = useState({});
  const [itemInfo, setItemInfo] = useState<any>(null);
  const [loadingItemInfo, setLoadingItemInfo] = useState(true);
  const [linkParams] = useSearchParams();

  const [openMaterialModal, setOpenMaterialModal] = useState(false);

  useEffect(() => {
    (async () => {
      if (linkParams.get('parent_id') && linkParams.get('parent_id') !== null) {
        const categoryMaterial = await getCategoryMaterial(linkParams.get('parent_id'));
        setItemInfo(categoryMaterial.data);
        setLoadingItemInfo(false);
      } else {
        setItemInfo(null);
        setLoadingItemInfo(false);
      }
    })();

    setParams({ ...params, level: 1, materialCategoryId: linkParams.get('parent_id') });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkParams]);

  useEffect(() => {
    setParams({
      ...params,
      level: level,
      materialCategoryId: linkParams.get('parent_id'),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkParams.get('parent_id'), level]);

  const CategoryMaterialColumns: ReadonlyArray<Column<User>> = [
    {
      Header: (props: any) => (
        <CustomHeader tableProps={props} title='Familie' className='min-w-50px' params={params} setParams={setParams} />
      ),
      id: 'material_categories_lvl_1_name',
      Cell: ({ ...props }) => props.data[props.row.index]?.material_category?.parent?.name,
    },
    {
      Header: (props: any) => (
        <CustomHeader
          tableProps={props}
          title='Sub-Familie'
          className='min-w-50px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'material_categories_lvl_2_name',
      Cell: ({ ...props }) => props.data[props.row.index]?.material_category?.name,
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Sub-Article'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'name',
      Cell: ({ ...props }) => props.data[props.row.index].name,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Code' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'code',
      Cell: ({ ...props }) => props.data[props.row.index].code,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Brand' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'brand',
      Cell: ({ ...props }) => props.data[props.row.index].brand,
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />,
      id: 'actions',
      Cell: ({ ...props }) => {
        return (
          <div>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => onOpenEditMaterial(props.row.original.id)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() =>
                Swal.fire({
                  icon: 'error',
                  title: 'Esti sigur?',
                  showCancelButton: true,
                  confirmButtonText: 'Sterge',
                  cancelButtonText: 'Anulare',
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                }).then(result => {
                  if (result.isConfirmed) {
                    deleteMaterial(props.row.original.id).then(() => {
                      getData(params);
                    });
                  }
                })
              }>
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </div>
        );
      },
    },
  ];

  const onCloseMaterialModal = () => {
    getData(params);
    setOpenMaterialModal(false);
    setMaterialData({});
  };

  const onOpenEditMaterial = async (id: any) => {
    const categoryMaterial = await getMaterial(id);
    setMaterialData(categoryMaterial.data);
    setOpenMaterialModal(true);
  };

  const onHandleFilter = (data: any) => {
    const newData = {
      materialCategoryId: data.materialCategoryId,
    };
    getData({ ...params, ...newData, page: 1 });
  };

  const getBreadcrumb = () => {
    if (itemInfo && itemInfo.parent?.parent?.name) {
      return [
        {
          title: 'Familii',
          path: '/materials',
          isActive: false,
          isSeparator: false,
        },
        {
          title: '',
          path: ``,
          isActive: false,
          isSeparator: true,
        },
        {
          title: itemInfo.parent?.parent?.name,
          path: `/sub-families?parent_id=${itemInfo.parent?.parent?.id}`,
          isActive: false,
          isSeparator: false,
        },
        {
          title: '',
          path: ``,
          isActive: false,
          isSeparator: true,
        },
        {
          title: itemInfo?.name,
          path: `/sub-articles?parent_id=${itemInfo?.id}`,
          isActive: true,
          isSeparator: false,
        },
      ];
    }
  };

  if (loadingItemInfo) return <p>LOADING</p>;

  return (
    <>
      <PageTitle breadcrumbs={getBreadcrumb()} />

      <div className=''>
        <div className=''>
          <div>
            {openMaterialModal && (
              <MaterialAddEditModal
                openModal={openMaterialModal}
                onClose={onCloseMaterialModal}
                material={materialData}
              />
            )}
            <DataGrid
              columns={CategoryMaterialColumns}
              useDataContext={useMaterialsContext}
              addButton={{
                label: 'Add Cod sub-articol',
                action: () => setOpenMaterialModal(true),
              }}
              aditionalActions={
                <div>
                  <MaterialMultipleCategoryFilter
                    onFilterMaterials={onHandleFilter}
                    params={params}
                    setParams={setParams}
                    level={level}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { MaterialsTable };
