import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useFormik } from 'formik';

import { KTSVG } from '../../../_metronic/helpers';
import { useOffersProductsContext } from '../../../context/context';
import { SubArticleForm } from './SubArticleForm';

type Props = {
  openModal: any;
  onClose: any;
  product: any;
  offer: any;
};

const ProductsAddEditModal: FC<Props> = ({ openModal, onClose, product, offer }) => {
  const [newProductData, setNewProductData] = useState<any>({
    material_id: null,
    unit_id: null,
    currency_id: null,
    date: moment().format('YYYY-MM-DD'),
    price: null,
    status: '',
    id: null,
  });

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const { addOfferProduct, editOfferProduct, submitErrors } = useOffersProductsContext();

  const isEdit = Object.values(product).length > 0;

  const location = useLocation();

  const formik = useFormik({
    initialValues: product,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      let res: any = null;
      if (isEdit) {
        res = await editOfferProduct(location.pathname.replace(/\D/g, ''), newProductData, values.id);
      } else {
        res = await addOfferProduct(location.pathname.replace(/\D/g, ''), newProductData);
      }

      setSubmitting(false);

      if (res.error) {
        if (res.error.response.data) {
          Object.keys(res.error.response.data).forEach(name => {
            formik.setErrors({ ...formik.errors, [name]: res.error.response.data[name][0] });
          });
        }

        if (res.error.response.data[`message`]) {
          setIsErrorModalOpen(true);
        }
      } else {
        onClose && onClose();
      }
    },
  });

  return (
    <>
      {openModal && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_offer'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'>
            <div className='modal-dialog modal-dialog-centered ' style={{ maxWidth: '90vw', width: '90vw' }}>
              <div className={`modal-content ${submitErrors?.[`message`] ? 'error-message' : ''}`}>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>{isEdit ? 'Editare produs' : 'Adaugare produs'}</h2>
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => onClose()}
                    style={{ cursor: 'pointer' }}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <form
                    onSubmit={formik.handleSubmit}
                    id='kt_modal_add_user_form'
                    className='form d-flex flex-wrap w-100'
                    autoComplete={'off'}>
                    <div className='w-100 fv-row mb-7'>
                      <div>
                        <SubArticleForm
                          errors={submitErrors}
                          product={product}
                          newProductData={newProductData}
                          setNewProductData={setNewProductData}
                          isEdit={isEdit}
                          offer={offer}
                        />
                      </div>
                    </div>
                    <div className='w-100 fv-row mb-7'>
                      <button type='submit' className='btn btn-sm btn-primary' disabled={formik.isSubmitting}>
                        Salveaza
                      </button>
                    </div>
                  </form>
                </div>

                {submitErrors?.[`message`] && isErrorModalOpen && (
                  <div className='add-product-error-modal'>
                    <div>
                      <p>{submitErrors?.[`message`]}</p>
                      <button
                        onClick={() => {
                          setIsErrorModalOpen(false);
                        }}>
                        <i className='la la-close '></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  );
};

export { ProductsAddEditModal };
