import { AuthProvider } from './providers/AuthProvider';
import { UsersProvider } from './providers/UsersProvider';
import { ReportsProvider } from './providers/ReportsProvider';
import { CurrencyProvider } from './providers/CurrencyProvider';
import { PartnersProvider } from './providers/PartnersProvider';
import { CategoryMaterialsProvider } from './providers/CategoryMaterialsProvider';
import { MaterialsProvider } from './providers/MaterialsProvider';
import { ShopsProvider } from './providers/ShopsProvider';
import { OffersProvider } from './providers/OffersProvider';
import { OffersProductsProvider } from './providers/OffersProductsProvider';
import { MeasurementUnitsProvider } from './providers/MeasurementUnitsProvider';

export const Provider = ({ children }) => (
  <AuthProvider>
    <MeasurementUnitsProvider>
      <OffersProvider>
        <OffersProductsProvider>
          <ShopsProvider>
            <ReportsProvider>
              <CurrencyProvider>
                <UsersProvider>
                  <PartnersProvider>
                    <CategoryMaterialsProvider>
                      <MaterialsProvider>{children}</MaterialsProvider>
                    </CategoryMaterialsProvider>
                  </PartnersProvider>
                </UsersProvider>
              </CurrencyProvider>
            </ReportsProvider>
          </ShopsProvider>
        </OffersProductsProvider>
      </OffersProvider>
    </MeasurementUnitsProvider>
  </AuthProvider>
);
