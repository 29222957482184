import { useState, FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DataGrid } from '../dataGrid/DataGrid';
import { useIntl } from 'react-intl';
import { CustomHeader } from '../dataGrid/CustomHeader';
import { Column } from 'react-table';
import { User } from '../apps/user-management/users-list/core/_models';
import { useOffersProductsContext, useOffersContext } from '../../../context/context';
import { KTSVG } from '../../../_metronic/helpers';
import Swal from 'sweetalert2';
import { PageTitle } from '../../../_metronic/layout/core';
import { useNavigate } from 'react-router-dom';

import { ProductsAddEditModal } from './ProductsAddEditModal';

type Props = {
  partner: any;
};

const ProductsTable: FC<Props> = () => {
  const intl = useIntl();
  const { gridData, getOfferProduct, deleteOfferProduct } = useOffersProductsContext();
  const { getOffer, offer } = useOffersContext();
  const { params, setParams, getData } = gridData;
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(true);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const location = useLocation();
  const ID = location.pathname.replace(/\D/g, '');

  const navigate = useNavigate();

  const DealsColumns: ReadonlyArray<Column<User>> = [
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Material'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'material',
      Cell: ({ ...props }) => {
        if (props.row.original.material_select) {
          return (
            <span
              data-bs-toggle='tooltip'
              title={
                props.row.original.material_select.level_one_name +
                ' -> ' +
                props.row.original.material_select.level_two_name +
                ' -> ' +
                props.row.original.material_select.level_three_name +
                ' -> ' +
                props.row.original.material_select.level_four_name
              }>
              {props.data[props.row.index].material.name}
            </span>
          );
        }
        return <span>{props.data[props.row.index].material.name}</span>;
      },
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Unitate de masura'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'unit',
      Cell: ({ ...props }) => props.data[props.row.index].unit.symbol,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Data' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'date',
      Cell: ({ ...props }) => props.data[props.row.index].date,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Valuta' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'currency',
      Cell: ({ ...props }) => props.data[props.row.index].currency.name,
    },
    {
      Header: props => (
        <CustomHeader tableProps={props} title='Pret' className='min-w-150px' params={params} setParams={setParams} />
      ),
      id: 'price',
      Cell: ({ ...props }) => props.data[props.row.index].price,
    },
    {
      Header: props => (
        <CustomHeader
          tableProps={props}
          title='Observatii'
          className='min-w-150px'
          params={params}
          setParams={setParams}
        />
      ),
      id: 'notes',
      Cell: ({ ...props }) => props.data[props.row.index].notes,
    },
    {
      Header: props => <CustomHeader tableProps={props} title='Actiuni' className='text-end min-w-100px' />,
      id: 'actions',
      Cell: ({ ...props }) => {
        return (
          <div>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => onOpenEditDeal(props.row.original)}>
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() =>
                Swal.fire({
                  icon: 'error',
                  title: 'Esti sigur?',
                  showCancelButton: true,
                  confirmButtonText: 'Sterge',
                  cancelButtonText: 'Anulare',
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                }).then(result => {
                  if (result.isConfirmed) {
                    deleteOfferProduct(location.pathname.replace(/\D/g, ''), props.row.original.id).then(() => {
                      getData(params);
                    });
                  }
                })
              }>
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </div>
        );
      },
    },
  ];

useEffect(() => {
  setLoading(true);
  getOffer(ID).then(() => setLoading(false));

  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [ID]);

const onCloseModal = () => {
  getData(params);
  setOpenOfferModal(false);
  setProductData({});
};

const onOpenEditDeal = async (values: { id: any }) => {
  const product = await getOfferProduct(ID, values?.id);

  if (product.data) {
    setProductData(product.data);
    setOpenOfferModal(true);
  }
  if (product.error) {
    Swal.fire({
      icon: 'error',
      title: product.error.response.status + ': ' + product.error.response.data.message,
    });
  }
};

return (
  <>
    {!loading && (
      <>
      <PageTitle breadcrumbs={[]}>
        {`${intl.formatMessage({ id: 'MENU.PRODUSE' })} - ${offer?.partner?.company_name} - ${offer?.status} - Nr:${
          offer?.number
        }`}
      </PageTitle>
      <button
            className='btn btn-primary mb-3 back-btn'
            onClick={() => {
              navigate(-1);
            }}>
            Inapoi
      </button>
      </>
    )}

    <div>
      <div>
        <div>
          {openOfferModal && (
            <ProductsAddEditModal openModal={openOfferModal} product={productData} offer={offer} onClose={onCloseModal} />
          )}
          <DataGrid
            columns={DealsColumns}
            useDataContext={useOffersProductsContext}
            addButton={{
              label: 'Adaugare material',
              action: () => setOpenOfferModal(true),
            }}
          />
        </div>
      </div>
    </div>
  </>
);
};

export { ProductsTable };
