import React, { useState } from 'react';
import { api } from '../api';

export const UsersContext = React.createContext();

export const UsersProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [gridParams, setGridParams] = useState({
    page: 1,
    limit: 10,
  });
  const [users, setUsers] = useState({
    data: [],
  });
  const [roles, setRoles] = useState();
  const [departs, setDeparts] = useState();
  const [permissions, setPermissions] = useState();

  const getUsers = async query => {
    setLoading(true);
    return await api
      .get('/api/user', {
        params: query,
      })
      .then(response => {
        setLoading(false);
        setUsers(response.data);
        return { data: response.data };
      })
      .catch(error => {
        setLoading(false);
        return { error };
      });
  };

  const addUser = async data => {
    return await api
      .post('/api/user', data)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  const deleteUser = async (id, data) => {
    return await api
      .delete(`/api/user/${id}`)
      .then(response => ({ data: response.data }))
      .catch(error => ({ error }));
  };

  const editUser = async (id, data) => {
    return await api
      .put(`/api/user/${id}`, data)
      .then(response => {
        // setUsers([...response.data, ...users]);
        return { data: response.data };
      })
      .catch(error => {
        return { error };
      });
  };

  const getRoles = async () => {
    return await api
      .get('/api/roles')
      .then(response => {
        setRoles(response.data);
        return { data: response.data };
      })
      .catch(error => {
        return { error };
      });
  };

  const getPermissions = async () => {
    return await api
      .get('/api/permissions')
      .then(response => {
        setPermissions(response.data);
        return { data: response.data };
      })
      .catch(error => {
        return { error };
      });
  };

  const getDeparts = async () => {
    return await api
      .get('/api/report/departs-list')
      .then(response => {
        setDeparts(response.data);
        return { data: response.data };
      })
      .catch(error => {
        return { error };
      });
  };

  const checkChangeRole = async query => {
    return await api
      .get('/api/check-change-role', { params: query })
      .then(({ data }) => ({ data }))
      .catch(error => ({ error }));
  };

  const checkUniqueness = async query => {
    return await api
      .get('/api/check-uniqueness', { params: query })
      .then(({ data }) => ({ data }))
      .catch(error => ({ error }));
  };
  return (
    <UsersContext.Provider
      value={{
        users,
        roles,
        departs,
        permissions,
        getUsers,
        addUser,
        editUser,
        getRoles,
        getDeparts,
        getPermissions,
        checkChangeRole,
        checkUniqueness,
        deleteUser,
        gridData: {
          getData: getUsers,
          isLoading: loading,
          data: users?.data || [],
          links: users?.links || [],
          params: gridParams,
          setParams: setGridParams,
          resetState: () => setUsers({ data: [] }),
        },
      }}>
      {children}
    </UsersContext.Provider>
  );
};
