import { FC, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { KTSVG } from '../../../_metronic/helpers';

import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import {
  usePartnersContext,
  useOffersContext,
  useMeasurementUnitsContext,
  useShopsContext,
} from '../../../context/context';
import Select from 'react-select';
import { MultiSelect } from 'react-multi-select-component';
import { ListLoading } from '../dataGrid/ListLoading';
import moment from 'moment';

type Props = {
  openModal: any;
  onClose: any;
  offer: any;
};

const StatusOptions = [
  {
    label: 'Offer',
    value: 'OFFER',
  },
  {
    label: 'Contract',
    value: 'CONTRACT',
  },
  {
    label: 'Expired Contract',
    value: 'EXPIRED_CONTRACT',
  },
  {
    label: 'Terminated Contract',
    value: 'TERMINATED_CONTRACT',
  },
];

const editDealSchema = Yup.object().shape({
  shops: Yup.array().required('Magazul este obligatoriu!'),
});

const DealAddEditModal: FC<Props> = ({ openModal, onClose, offer }) => {
  const isEdit = Object.values(offer).length > 0;

  const { getMeasurementUnits } = useMeasurementUnitsContext();
  const { getPartnersForReportsFilters, partners } = usePartnersContext();
  const { getShopsForReportsFilters, shops } = useShopsContext();
  const { addOffer, editOffer, submitErrors } = useOffersContext();
  const [selectedPartner, setSelectedPartner] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [shopItems, setShopsItem] = useState<any>([]);
  const [status, setStatus] = useState<any>(StatusOptions.find(q => q.value === offer.status));
  const [isuedDate, setIsuedDate] = useState(new Date());
  const [validityDate, setValidityDate] = useState(new Date());
  const [number, setNumber] = useState<any>(0);

  const [form] = useState<any>({
    ...offer,
    partner: offer.partner_id || null,
    status: StatusOptions.find(q => q.value === offer.status) || 'OFFER',
    shops: offer.shops || [],
    id: offer ? offer.id : null,
    issued_at: offer.issued_at || null,
    validity_date: offer.validity_date || null,
    number: offer.number || null
  });

  useEffect(() => {
    fetchData();

    if (isEdit) {
      setSelectedPartner({
        value: offer?.partner?.id,
        label: offer?.partner?.company_name,
      });
      setNumber(offer.number);
      setIsuedDate(new Date(offer?.issued_at));
      setValidityDate(new Date(offer?.validity_date));

      setShopsItem(offer?.shops?.map((q: any) => ({ value: q.id, label: q.name })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    await getMeasurementUnits();
    await getPartnersForReportsFilters();
    await getShopsForReportsFilters();
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: form,
    validationSchema: editDealSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const data = {
        partner_id: selectedPartner?.value || values.partner_id,
        shops: shopItems.length > 0 ? shopItems.map((q: any) => q.value) : values.shops.map((q: any) => q.value),
        status: status.value || values.status.value,
        issued_at: moment(isuedDate).format('YYYY-MM-DD'),
        validity_date: moment(validityDate).format('YYYY-MM-DD'),
        number: number || values.number
      };
      let res: any = null;
      if (isEdit) {
        res = await editOffer(offer.id, data);
      } else {
        res = await addOffer(data);
      }

      setSubmitting(false);

      if (res.error) {
        if (res.error.response.data) {
          Object.keys(res.error.response.data).forEach(name => {
            formik.setErrors({ ...formik.errors, [name]: res.error.response.data[name][0] });
          });
        }
      } else {
        onClose && onClose();
      }
    },
  });

  const onHandleSelectPartner = (partner: any) => {
    setSelectedPartner(partner);
    setShopsItem([]);
  };

  const onChangeShopsSelect = (item: any) => {
    setShopsItem(item);
  };

  const onChangeStatusSelect = (item: any) => {
    setStatus(item);
    if (isEdit) {
      setValidityDate(new Date());
    }
  };

  const onChangeDate = (value: any) => {
    setIsuedDate(value);
  }
  const onChangeValidityDate = (value: any) => {
    setValidityDate(value);
  }
  const onChangeNumber = (number: any) => {
    setNumber(number.target.value);
  }

  if (loading) return <ListLoading />;

  return (
    <>
      {openModal && (
        <>
          <div
            className='modal fade show d-block'
            id='kt_modal_add_offer'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'>
            <div className='modal-dialog modal-dialog-centered ' style={{ maxWidth: '90vw', width: '90vw' }}>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h2 className='fw-bolder'>{isEdit ? 'Editare oferta' : 'Adaugare oferta'}</h2>
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => onClose()}
                    style={{ cursor: 'pointer' }}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                  <form
                    onSubmit={formik.handleSubmit}
                    id='kt_modal_add_user_form'
                    className='form d-flex flex-wrap w-100'
                    autoComplete={'off'}>
                    <div className='w-50 px-2 mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Partener</label>
                      <Select
                        onChange={onHandleSelectPartner}
                        options={partners?.map((q: any) => ({ label: q.company_name, value: q.id }))}
                        placeholder='Partener'
                        name='partner'
                        isDisabled={isEdit}
                        value={selectedPartner}
                        className='form-control-async-select'
                      />
                      {submitErrors?.partner_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{submitErrors?.partner_id.join(' ')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className=' w-50 px-2 mb-7'>
                      <label className='form-label fs-6 fw-bold'>Magazine</label>
                      <MultiSelect
                        onChange={onChangeShopsSelect}
                        options={shops?.map((item: any) => ({ label: item.name, value: item.id }))}
                        labelledBy='Magazine'
                        dropdown-heading
                        value={shopItems || offer?.shops}
                        className='form-control-async-select-multiple'
                      />
                      {submitErrors?.shops && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{submitErrors?.shops.join(' ')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className=' w-50 px-2 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Statut</label>
                      <Select
                        onChange={onChangeStatusSelect}
                        placeholder='Statut'
                        name='status'
                        options={StatusOptions?.map((q: any) => ({ label: q.label, value: q.value }))}
                        value={status}
                        className='form-control-async-select'
                      />
                      {submitErrors?.status && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{submitErrors?.status.join(' ')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='w-50 px-2 mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Numar</label>
                      <input
                        placeholder='Numar'
                        type='number'
                        name='Numar'
                        min='0'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        value={number}
                        onChange={onChangeNumber}
                      />
                      {submitErrors?.number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{submitErrors?.number.join(' ')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className=' w-50 px-2 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Emis la:</label>
                      <DatePicker
                        selected={isuedDate}
                        maxDate={new Date()}
                        onChange={onChangeDate}
                        className='form-control form-control-solid form-select-sm form-select mb-3 mb-lg-0'
                      />
                      {submitErrors?.issued_at && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{submitErrors?.issued_at.join(' ')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className=' w-50 px-2 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Data Valabilitate:</label>
                      <DatePicker
                        selected={validityDate}
                        onChange={onChangeValidityDate}
                        className='form-control form-control-solid form-select-sm form-select mb-3 mb-lg-0'
                      />
                      {submitErrors?.validity_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{submitErrors?.validity_date.join(' ')}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='w-100 fv-row mb-7'>
                      <button type='submit' className='btn btn-sm btn-primary' disabled={formik.isSubmitting}>
                        Salveaza
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  );
};

export { DealAddEditModal };
