import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { UsersWrapper } from '../pages/users/UsersWrapper';
import { PartnersWrapper } from '../pages/partners/PartnersWrapper';
import { MaterialsWrapper } from '../pages/materials/MaterialsWrapper';
import { CategoryMaterialsWrapper } from '../pages/categoryMaterialsWrapper/CategoryMaterialsWrapper';
import { CurrencyWrapper } from '../pages/currency/CurrencyWrapper';
import { ShopsWrapper } from '../pages/shops/ShopsWrapper';
import { DealsWrapper } from '../pages/deals/DealsWrapper';
import { ReportsWrapper } from '../pages/reports/ReportsWrapper';
import { ReportChartWrapper } from '../pages/reportChart/ReportChartWrapper';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { useAuthContext } from '../../context/context';
import { ROLE_KABA } from '../../constants';

const PrivateRoutes = () => {
  const { authUser } = useAuthContext();
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/reports' />} />
        {/* Pages */}

        <Route path='/dashboard' element={<DashboardWrapper />} />

        <Route path='users' element={<UsersWrapper />} />

        {authUser.role === ROLE_KABA && (
          <>
            <Route path='partners' element={<PartnersWrapper />} />
            <Route path='materials' element={<CategoryMaterialsWrapper level={1} />} />
            <Route path='sub-families' element={<CategoryMaterialsWrapper level={2} />} />
            <Route path='sub-articles' element={<MaterialsWrapper level={4} />} />
            <Route path='currency' element={<CurrencyWrapper />} />
            <Route path='deals' element={<DealsWrapper level={1} />} />
            <Route path='deals/:id/products' element={<DealsWrapper level={2} />} />
            <Route path='Shops' element={<ShopsWrapper />} />
            <Route path='reports' element={<ReportsWrapper />} />
            <Route path='reports/chart/:data' element={<ReportChartWrapper />} />
          </>
        )}

        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route path='/' element={<Navigate to='/reports' />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
