import { FC, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';
import { KTSVG } from '../../../_metronic/helpers';

import { useFormik } from 'formik';
import { useCategoryMaterialsContext } from '../../../context/context';

import clsx from 'clsx';

type Props = {
  openModal: any;
  onClose: any;
  categoryMaterial: any;
  level?: any;
};

const editCategoryMaterialSchema = Yup.object().shape({
  name: Yup.string().required('Numele este obligatoriu!'),
});

const CategoryMaterialAddEditModal: FC<Props> = ({ openModal, onClose, categoryMaterial, level }) => {
  const isEdit = Object.values(categoryMaterial).length > 0;
  const [searchParams] = useSearchParams();
  const [firstLevelOptions, setFirstLevelOptions] = useState([]);
  const [secondFilterOptions, setSecondFilterOptions] = useState([]);
  const [familiesValue, setFamiliesValue] = useState<any>(null);
  const [filterParams] = useState({
    parent_id: searchParams.get('parent_id'),
  });

  const [subFamiliesItem, setSubFamiliesItem] = useState<any>(null);

  const {
    addCategoryMaterial,
    editCategoryMaterial,
    getCategoryMaterialsForFilters,
    getCategoryMaterialDetalisForFilters,
  } = useCategoryMaterialsContext();

  useEffect(() => {
    getCategoryMaterialsForFilters({ level: 1 }).then((res: any) => {
      setFirstLevelOptions(res.data.data);
    });
    switch (level) {
      case 2:
        if (filterParams.parent_id) {
          setFamiliesValue(filterParams.parent_id);
        } else {
          setFamiliesValue(categoryMaterial.parent_id);
        }
        break;
      case 3:
        if (filterParams.parent_id) {
          getCategoryMaterialDetalisForFilters(filterParams.parent_id).then((res: any) => {
            setSecondFilterOptions(res?.data?.parent?.childs);
            setFamiliesValue(res?.data?.parent?.id);

            if (isEdit) {
              setSubFamiliesItem({
                label: res.data?.name,
                value: res.data?.id,
              });
            }
          });
        } else {
          getCategoryMaterialDetalisForFilters(categoryMaterial.parent_id).then((res: any) => {
            setSecondFilterOptions(res?.data?.parent?.childs);
            setFamiliesValue(res?.data?.parent?.id);

            if (isEdit) {
              setSubFamiliesItem({
                label: res.data?.name,
                value: res.data?.id,
              });
            }
          });
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editData] = useState({
    ...categoryMaterial,
    code: categoryMaterial.code || undefined,
    name: categoryMaterial.name || '',
    parent_id: categoryMaterial.parent_id || undefined,
    id: categoryMaterial ? categoryMaterial.id : undefined,
  });

  const formik = useFormik({
    initialValues: editData,
    validationSchema: editCategoryMaterialSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      let data: any = {
        name: values.name,
        code: values.code,
        parent_id: null,
      };

      if (level === 2) {
        data.parent_id = familiesValue;
      }
      if (level === 3) {
        data.parent_id = subFamiliesItem?.value;
      }

      let res: any = null;
      if (isEdit) {
        res = await editCategoryMaterial(categoryMaterial.id, data);
      } else {
        res = await addCategoryMaterial(data);
      }

      setSubmitting(false);

      if (res.error) {
        if (res.error.response.data) {
          Object.keys(res.error.response.data).forEach(name => {
            formik.setErrors({
              ...formik.errors,
              [name]: res.error.response.data[name][0],
            });
          });
        }
      } else {
        onClose && onClose();
      }
    },
  });

  const getFamilyOptions = () => {
    return firstLevelOptions.map((item: any) => ({ label: item.name, value: item.id }));
  };

  const getSubFamilyOptions = () => {
    return secondFilterOptions.map((item: any) => ({ label: item.name, value: item.id }));
  };

  const onChangeFamilySelect = (e: any) => {
    setFamiliesValue(e.value);

    getCategoryMaterialDetalisForFilters(e.value).then((res: any) => {
      setSecondFilterOptions(res?.data?.childs);
    });

    setSubFamiliesItem(null);
  };

  const onChangeSubFamilySelect = (e: any) => {
    setSubFamiliesItem(getSubFamilyOptions().find((item: any) => item.value === parseInt(e.value)));
  };

  const addTitle = level === 1 ? 'Familie' : level === 2 ? 'Sub-Familie' : '';

  return (
    <>
      <div className='modal fade show d-block' id='kt_modal_add_category' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>{isEdit ? `Edit ${addTitle}` : `Add ${addTitle}`}</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => onClose()}
                style={{ cursor: 'pointer' }}>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <form onSubmit={formik.handleSubmit} id='kt_modal_add_user_form' className='form' autoComplete={'off'}>
                <div className='fv-row mb-7'>
                  <label className='fw-bold fs-6 mb-2'>Code</label>
                  <input
                    placeholder='Code'
                    {...formik.getFieldProps('code')}
                    type='text'
                    name='code'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.code && formik.errors.code },
                      {
                        'is-valid': formik.touched.code && !formik.errors.code,
                      }
                    )}
                    autoComplete='off'
                    value={formik?.values?.code}
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.code && formik.errors.code && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{typeof formik.errors.code === 'string' && formik.errors.code}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-7'>
                  <label className='required fw-bold fs-6 mb-2'>Nume</label>
                  <input
                    placeholder='Nume'
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.name && formik.errors.name },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    autoComplete='off'
                    value={formik?.values?.name}
                    disabled={formik.isSubmitting}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{typeof formik.errors.name === 'string' && formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>

                <>
                  {level > 1 && (
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Familie</label>
                      <Select
                        onChange={onChangeFamilySelect}
                        options={getFamilyOptions()}
                        placeholder='Familie'
                        name='familii'
                        value={getFamilyOptions().find((item: any) => item.value === parseInt(familiesValue))}
                        className='form-control-async-select form-control-solid form-select-solid'
                      />
                      {formik.errors.parent_id && !familiesValue && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>
                              {typeof formik.errors.parent_id === 'string' && formik.errors.parent_id}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {(level === 3 || level === 4) && (
                    <div className='fv-row mb-7'>
                      <label className='required fw-bold fs-6 mb-2'>Sub-Familie</label>
                      <Select
                        onChange={onChangeSubFamilySelect}
                        options={getSubFamilyOptions()}
                        placeholder='Sub-Familie'
                        name='sub-familii'
                        value={subFamiliesItem}
                        className='form-control-async-select form-control-solid form-select-solid'
                      />
                      {formik.errors.parent_id && !subFamiliesItem && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>
                              {typeof formik.errors.parent_id === 'string' && formik.errors.parent_id}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
                <button type='submit' className='btn btn-sm btn-primary' disabled={formik.isSubmitting}>
                  Salveaza
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  );
};

export { CategoryMaterialAddEditModal };
