import { FC, useMemo } from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';

type Props = {
  data: any;
};

const ReportChart: FC<Props> = ({ data }) => {
  const { currency } = data;
  const chartSeries = useMemo(() => {
    const tmpChartSeries: any = [];
    data.report.forEach((line: any) => {
      const product = line.material[0];
      tmpChartSeries.push({
        type: 'line',
        name: line.partner.partner + ' -> ' + product?.name + '(' + product?.unit + ')',
        data: line.material.map((historyItem: any) => ({
          x: moment(historyItem.date).add(3, 'hours'),
          y: parseFloat(historyItem.priceConverted),
          id: historyItem.id,
        })),
      });
    });

    return tmpChartSeries;
  }, [data]);

  const options: any = {
    chart: {
      height: 350,
      zoom: {
        enabled: false,
      },
      id: 'basic-line',
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'Draft Grafic Pentru Materiale Filtrate',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', '#f3f3f3'],
        opacity: 0.5,
      },
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return `${val.toFixed(2)} ${data.currency}`;
        },
      },
      title: {
        text: `Price(${currency})`,
      },
    },
  };

  return (
    <div>
      <Chart options={options} series={chartSeries} height='750' type='line' />
    </div>
  );
};

export { ReportChart };
